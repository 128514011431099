@import url("https://use.typekit.net/ghc0jsg.css");

.outer {
	display: inline-block;
	padding: 64px 88px 32px;
	background-color: #000;
}
@media (max-width: 600px) {
	.outer {
		padding: 32px 30px 16px;
		line-height: 0.2;
	}
}

.text, .blinkingText {
    font-family: ht-neon, sans-serif;
    font-size: 130px;
    font-weight: normal;
    white-space: nowrap;
    margin: 0;
}
@media (max-width: 600px) {
	.text, .blinkingText {
		font-size: 80px;
	}
}

.text {
    color: #ff0037;
    text-shadow:
		0 0  26px rgb(255, 0, 98), 
		0 0 5px rgb(201, 24, 68);
}
.blinkingText {	
	color: #251225;
	/* text-shadow:  */
		/* 1px 1px 12px rgba(255, 0, 217, 0.1),  */
		/* 0 0 18px rgba(255, 31, 221, 0.1),  */
        /* 0 0 5px rgba(201, 24, 175, 0.1); */
}

.blinkingText__on {
	opacity: 1;
	color: #ff00b3;
	text-shadow: 
		0 0 2px rgb(255, 0, 157), 
		0 0 8px rgb(255, 0, 191);
}

.outer__small {
	padding: 0 88px;
	height: 171px;
}
.small {
	font-size: 64px;
	line-height: 1;
	vertical-align: text-bottom;
}
@media (max-width: 600px) {
	.outer__small {
		padding: 16px 48px;
		height: 121px;
	}
	.small {
		font-size: 50px;
		vertical-align: text-top;
	}
}
