@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:200,400,700&display=swap');

.sectionContent {
	font-family: 'Nanum Gothic', sans-serif;
	font-size: 20px;
	line-height: 35px;
	margin-bottom: 80px;
	display: flex;
	justify-content: flex-start;
}
@media (max-width: 600px) {
	.sectionContent {
		display: block;
	}
	.imageContainer {
		display: block;
	}
}

.sectionContent h2 {
	/* margin: 0; */
}

.itemDate {
	font-size: 16px;
	opacity: 0.6;
}

.addButton, .editButton {
	background: none;
	border: none;
	margin-bottom: 32px;
	font-size: 18px;
	padding: 8px 16px;
	border-radius: 3px;
	font-weight: bold;
	color: white;
	background-color: blueviolet;
	cursor: pointer;
	transition: 0.2s background-color;
	box-shadow: 0 1px 0 rgba(0,0,0,0.4), inset 0 1px 0 rgba(255,255,255,0.4);
}
.editButton {
	background-color: rgb(240, 224, 255);
	padding: 4px 8px;
	font-size: 14px;
	border: 1px solid blueviolet;
	color: blueviolet;
}
.deleteButton  {
	border: none;
	color: red;
	font-weight: normal;
}
.addButton:hover, .addButton:focus {
	background-color: rgb(162, 92, 228);
}

.sectionContent h1 {
	font-size: 22px;
	margin: 0;
}

.sectionContent p {
	margin: 0;
	font-size: 16px
}

.imageContainer {
	width: 260px;
	min-width: 260px;
	margin-right: 16px;
}
@media (max-width: 600px) {
	.imageContainer {
		width: 100%;
	}
}
.displayedImage {
	max-width:100%;
	max-height:100%;
}
.displayedContent {
	flex: 1;
	word-wrap: normal;
}
.pageContainer {
	max-width: 900px;
	margin: 0 auto;
	padding: 16px;
}
