.fileEditorContainer {
    margin-bottom: 16px;
	position: relative;
	flex: 0;
}
.fileContainer {
    width: 260px;
	margin-right: 16px;
}
.displayedFile {
    max-width:100%;
	max-height:100%;
}
.fileButton {
    position: absolute;
	background: none;
	cursor: pointer;
	border: none;
	bottom: 0;
	left: 0;
	height: 44px;
	width: 260px;
	background-color: rgb(240, 224, 255);
	font-size: 14px;
	border: 1px solid blueviolet;
	color: blueviolet;
}
.fileList {
	position: fixed;
	z-index: 10000;
	top: 25%;
	left: 15%;
	padding: 20px 40px;
	background-color: white;
	border: 1px solid #e0e0e0;
	box-shadow: 0 0 20px rgba(0,0,0,0.6);
}
.fileList ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.filePreviewListItem {
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 8px;
}
.fileListCloseButton {
	background: none;
	border: none;
	font-size: 24px;
	position: absolute;
	top: 4px;
	right: 4px;
	z-index: 99999;
}
.addNewFileButton {
	margin: 16px 0;
	color: blueviolet;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	display: block;
	width: 100%;
}
.imgPreview {
    max-width: 150px;
}