@import url("https://use.typekit.net/ghc0jsg.css");

.siteName__outer {
	display: inline-block;
	position: fixed;
	z-index: 10;
	padding: 32px 64px;
	top: 16px;
	left: 16px;
	background-color: #000;
}

.siteName {	
	color: #251225;
	text-shadow: 
		1px 1px 12px rgba(255, 0, 217, 0.1), 
		0 0 18px rgba(255, 31, 221, 0.1), 
		0 0 5px rgba(201, 24, 175, 0.1);
}

.siteName__on {
	opacity: 1;
	color: #fecbff;
	text-shadow: 
		10px 10px 132px rgba(255, 0, 217, 1), 
		10px 10px 132px rgba(255, 0, 217, 1), 
		10px 10px 132px rgba(255, 0, 217, 1), 
		10px 10px 132px rgba(255, 0, 217, 1), 
		0 0 180px rgba(255, 31, 221, 0.9), 
		0 0 5px rgba(201, 24, 175, 1);
}

.siteName h1 {
	font-family: ht-neon, sans-serif;
	font-size: 119px;
	font-weight: normal;
	white-space: nowrap;
	margin: 0;
}
.homeNav {
	margin-left: 88px;
}
@media (max-width: 600px) {
	.homeNav {
		margin-left: 0;
	}
}

.video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.homeLogo {
	max-width: 100%;
	display: block;
}

.navGizmo {
	position: fixed;
	top: 8px;
	left: 8px;
	display: flex;
	width: 100px;
	height: 100px;
	justify-content: space-around;
	align-items: stretch;
	flex-wrap: wrap;
}

.navGizmo__top {
	display: block;
	width: 100%;
	text-align: center;
	font-size: 12px;
}

.sectionsList {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.sectionsList li {
	font-size: 32px;
	margin: 0 0 256px;
	color: #f9f9f9;
}

.sectionsList h2 {
	font-family: ht-neon, sans-serif;
	font-weight: normal;
	color: #ffeeff;
}
