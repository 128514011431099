.add, .delete, .ghost {
    background: none;
	border: none;
	border-radius: 3px;
    padding: 4px 8px;
	font-size: 14px;
	cursor: pointer;
	transition: 0.2s background-color;
	box-shadow: 0 1px 0 rgba(0,0,0,0.4), inset 0 1px 0 rgba(255,255,255,0.4);
}

.add {
    margin-bottom: 32px;
	font-size: 18px;
    padding: 8px 16px;
    background-color: blueviolet;
    color: white;
    font-weight: bold;
}

.delete {
    color: red;
    background-color: transparent;
}

.ghost {
    background-color: rgb(240, 224, 255);
	border: 1px solid rgb(138, 43, 226);
	color: blueviolet;
}