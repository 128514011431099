.sectionContent {
	font-size: 20px;
	line-height: 35px;
	white-space: pre-wrap;
	margin-bottom: 80px;
	display: flex;
	justify-content: flex-start;
}
@media (max-width: 600px) {
	.sectionContent {
		display: block;
	}
	.imageContainer {
		display: block;
	}
}

.itemDate {
	font-size: 16px;
	opacity: 0.6;
}

.sectionContent h1 {
	font-size: 22px;
	margin: 0;
}

.sectionContent p {
	margin: 0;
	font-size: 16px
}

.imageContainer {
	width: 260px;
	min-width: 260px;
	margin-right: 16px;
}
@media (max-width: 600px) {
	.imageContainer {
		width: 100%;
	}
}
.displayedImage {
	max-width:100%;
	max-height:100%;
}
.displayedContent {
	flex: 1;
	word-wrap: normal;
}
.pageContainer {
	max-width: 900px;
	margin: 0 auto;
}

.ownPage {
	padding-top: 48px;
}