@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700&display=swap');

.classNav {
    position: fixed;
    top: 8px;
    width: 100%;
    text-align: center;
}

.classNav a {
    color: white;
    display: inline-block;
    margin-right: 8px;
    opacity: 0.6;
    transition: 0.2s opacity;
}
.classNav a:hover, .classNav a:focus {
  opacity: 1;
}

.classContainer {
    background: #000;
    color: #feebff;
    padding-top: 64px;
}

.stillPoseContainer {
    max-width: 1000px;
    height: 100vh;
}

.bgOn {
    background: linear-gradient( rgba(255, 3, 129, 0.1), rgba(255, 0, 221, 0.01) ) rgb(1, 0, 0);
}

.video {
    max-width: 100%;
}

.description {
    font-family: 'Nanum Gothic', sans-serif;
    color: #f8d4fa;
    opacity: 0.8;
    text-align: center;
    font-size: 16px;
    line-height: 1.4;
    width: 70%;
    margin: 24px auto 64px;
}

.videosContainer {
  padding-top: 64px; 
  text-align: center;
}
.videosContainer iframe {
  margin-bottom: 128px;
  max-width: 100%;
}

@font-face {
    font-family: 'classy';
    src:  url('/assets/fonts/classy.eot?xit0xr');
    src:  url('/assets/fonts/classy.eot?xit0xr#iefix') format('embedded-opentype'),
      url('/assets/fonts/classy.ttf?xit0xr') format('truetype'),
      url('/assets/fonts/classy.woff?xit0xr') format('woff'),
      url('/assets/fonts/classy.svg?xit0xr#classy') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .iconY, .iconClass {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'classy' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .iconY:before {
    position: relative;
    top: -.16em;
    content: "\e900";
  }
  .iconClass:before {
    content: "\e901";
  }
  