@import url(https://use.typekit.net/ghc0jsg.css);
@import url(https://use.typekit.net/ghc0jsg.css);
@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic:200,400,700&display=swap);
@import url(https://use.typekit.net/ghc0jsg.css);
@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic:200,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding-bottom: 24px;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

.active {
    color: black !important;
    text-decoration: none;
}

.Home_siteName__outer__15JyM {
	display: inline-block;
	position: fixed;
	z-index: 10;
	padding: 32px 64px;
	top: 16px;
	left: 16px;
	background-color: #000;
}

.Home_siteName__2zITU {	
	color: #251225;
	text-shadow: 
		1px 1px 12px rgba(255, 0, 217, 0.1), 
		0 0 18px rgba(255, 31, 221, 0.1), 
		0 0 5px rgba(201, 24, 175, 0.1);
}

.Home_siteName__on__1B1Sn {
	opacity: 1;
	color: #fecbff;
	text-shadow: 
		10px 10px 132px rgba(255, 0, 217, 1), 
		10px 10px 132px rgba(255, 0, 217, 1), 
		10px 10px 132px rgba(255, 0, 217, 1), 
		10px 10px 132px rgba(255, 0, 217, 1), 
		0 0 180px rgba(255, 31, 221, 0.9), 
		0 0 5px rgba(201, 24, 175, 1);
}

.Home_siteName__2zITU h1 {
	font-family: ht-neon, sans-serif;
	font-size: 119px;
	font-weight: normal;
	white-space: nowrap;
	margin: 0;
}
.Home_homeNav__vceds {
	margin-left: 88px;
}
@media (max-width: 600px) {
	.Home_homeNav__vceds {
		margin-left: 0;
	}
}

.Home_video__2N7_R {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.Home_homeLogo__2-uB4 {
	max-width: 100%;
	display: block;
}

.Home_navGizmo__t2Aba {
	position: fixed;
	top: 8px;
	left: 8px;
	display: flex;
	width: 100px;
	height: 100px;
	justify-content: space-around;
	align-items: stretch;
	flex-wrap: wrap;
}

.Home_navGizmo__top__3IaCu {
	display: block;
	width: 100%;
	text-align: center;
	font-size: 12px;
}

.Home_sectionsList__xjrl7 {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.Home_sectionsList__xjrl7 li {
	font-size: 32px;
	margin: 0 0 256px;
	color: #f9f9f9;
}

.Home_sectionsList__xjrl7 h2 {
	font-family: ht-neon, sans-serif;
	font-weight: normal;
	color: #ffeeff;
}

.Neon_outer__1Fyqb {
	display: inline-block;
	padding: 64px 88px 32px;
	background-color: #000;
}
@media (max-width: 600px) {
	.Neon_outer__1Fyqb {
		padding: 32px 30px 16px;
		line-height: 0.2;
	}
}

.Neon_text__1TUzn, .Neon_blinkingText__FfFoF {
    font-family: ht-neon, sans-serif;
    font-size: 130px;
    font-weight: normal;
    white-space: nowrap;
    margin: 0;
}
@media (max-width: 600px) {
	.Neon_text__1TUzn, .Neon_blinkingText__FfFoF {
		font-size: 80px;
	}
}

.Neon_text__1TUzn {
    color: #ff0037;
    text-shadow:
		0 0  26px rgb(255, 0, 98), 
		0 0 5px rgb(201, 24, 68);
}
.Neon_blinkingText__FfFoF {	
	color: #251225;
	/* text-shadow:  */
		/* 1px 1px 12px rgba(255, 0, 217, 0.1),  */
		/* 0 0 18px rgba(255, 31, 221, 0.1),  */
        /* 0 0 5px rgba(201, 24, 175, 0.1); */
}

.Neon_blinkingText__on__35Zg6 {
	opacity: 1;
	color: #ff00b3;
	text-shadow: 
		0 0 2px rgb(255, 0, 157), 
		0 0 8px rgb(255, 0, 191);
}

.Neon_outer__small__TwP8N {
	padding: 0 88px;
	height: 171px;
}
.Neon_small__2NjFW {
	font-size: 64px;
	line-height: 1;
	vertical-align: text-bottom;
}
@media (max-width: 600px) {
	.Neon_outer__small__TwP8N {
		padding: 16px 48px;
		height: 121px;
	}
	.Neon_small__2NjFW {
		font-size: 50px;
		vertical-align: text-top;
	}
}

.Link_link__N_GWc, .Link_link__N_GWc:visited, .Link_link__N_GWc:link {
    font-family: 'Nanum Gothic', sans-serif;
    color: blueviolet;
    font-size: 18px;
    line-height: 1.6;
    text-decoration: none;
}

.Link_light__3Mfpt, .Link_light__3Mfpt:visited, .Link_light__3Mfpt:link {
    display: block;
    color: #f8bbed;
}

.Link_padLeft__1C2Mg {
    padding-left: 32px;
}

.Link_inline__2kKRU {
    display: inline-block;
    padding-right: 0.5em;
    font-size: 16px !important;
}

.Link_plain__3la9_ {
    color: #222;
    text-decoration: none;
}

.Heading_heading__3BUfQ, .Heading_subHeading__2Ak8C {
    text-align: left;
    font-family: 'Nanum Gothic', sans-serif;
    opacity: 0.9;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.4;
}

.Heading_heading_neon__XG8MZ {
    font-family: ht-neon, 'Nanum Gothic', sans-serif;
}

.Heading_center__1AjpQ {
    text-align: center;
    width: 100%;
}

.Heading_subHeading__2Ak8C {
    font-size: 22px;
}

.Heading_large__CQ0wJ {
    font-size: 50px;
}

.Heading_noCause__3b25Z {
    font-size: 70px;
    font-weight: 200;
}

.Heading_light__1Wdu5 {
    color: #ffddf9;
}

.Heading_padTop__2NC0q {
    padding-top: 32px;
}

.Heading_inline__3oBrK {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    padding-right: 0.5em;
}

.Heading_bold__1gYjp {
    font-weight: bold;
}

.Heading_inline__3oBrK::after {
    content: ':';
}

.Heading_catastrophe__3rSLg {
    font-family: Arial;
}
.PhotoSeries_pageContainer__3H-q3 {
	max-width: 900px;
	margin: 0 auto;
	padding: 16px;
}
.PhotoSeries_pageContainer__3H-q3 img {
	max-width: 100%;
}

.PhotoSeries_PhotoDetail__2CgKo {
	max-width: 730px;
	margin: 0 auto;
	max-height: 80vh;
}
@media (min-width: 731px) {
	.PhotoSeries_PhotoDetail__2CgKo {
		min-height: 80vh;
	}
}
@media (max-width: 650px) {
	.PhotoSeries_PhotoDetail__2CgKo {
		max-height: 75vh;
	}
}
@media (max-width: 630px) {
	.PhotoSeries_PhotoDetail__2CgKo {
		max-height: 70vh;
	}
}
@media (max-width: 540px) {
	.PhotoSeries_PhotoDetail__2CgKo {
		max-height: 60vh;
	}
}
@media (max-width: 390px) {
	.PhotoSeries_PhotoDetail__2CgKo {
		min-height: 40vh;
	}
}

.PhotoSeries_PhotoDetail__2CgKo img {
	max-height: 80vh;
	max-width: 100%;
}

.PhotoSeries_photoGridContainer__20Mlq {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.PhotoSeries_photo__27HaS {
	flex: 0 1;
    padding-top: 32px;
	padding-bottom: 64px;
	margin-right: 24px;
}
.PhotoSeries_photo__27HaS:nth-child(3n) {
	margin-right: 0;
}

.PhotoSeries_photoImg__1nzZb {
	cursor: pointer;
	min-width: 200px;
	max-width: 100%;
}

@media (max-width: 740px) {
	.PhotoSeries_photoImg__1nzZb {
		min-width: 160px;
	}
}
@media (max-width: 660px) {
	.PhotoSeries_photoImg__1nzZb {
		min-width: 140px;
	}
}
@media (max-width: 583px) {
	.PhotoSeries_photoImg__1nzZb {
		min-width: 120px;
	}
}
@media (max-width: 500px) {
	.PhotoSeries_photoImg__1nzZb {
		min-width: 100px;
	}
}
@media (max-width: 423px) {
	.PhotoSeries_photoImg__1nzZb {
		min-width: 80px;
	}
}

.PhotoSeries_popupPhotoContainer__32zi0 {
	position: fixed;
	width: 100%;
	text-align: center;
	top: 70px;
	left: 0;
}
.PhotoSeries_popupPhoto__68J7f {
	position: relative;
	z-index: 99999;
}
.PhotoSeries_photoControls__dC40F {
	max-width: 730px;
	margin: 24px auto 0;
	margin-top: 24px;
	position: relative;
    display: flex;
    flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 99999;
}

.PhotoSeries_prev__8F8B9, .PhotoSeries_next__ijgtS {
    width: 100px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    background: none;
    font-size: 32px;
    opacity: 0.4;
}

.PhotoSeries_closeButton__3FXs1 {
	border: none;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	background: none;
	position: fixed;
	font-size: 48px;
	top: 0;
	right: 16px;
	line-height: 1;
	height: 48px;
}

.PhotoSeries_overlay__2fhyP {
	background-color: rgb(255,255,255);
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	z-index: 99998;
}

.PhotoSeries_HeaderBG__21njr {
	background-position: right;
	background-size: cover;
	background-color: #f8c9cf;
	margin-top: 32px;
	padding: 32px 0;
	color: white;
	box-shadow: 0 30px 100px 20px #f8c9cf;
}
.Button_add__6bU7-, .Button_delete__2cCUy, .Button_ghost__v_8dN {
    background: none;
	border: none;
	border-radius: 3px;
    padding: 4px 8px;
	font-size: 14px;
	cursor: pointer;
	-webkit-transition: 0.2s background-color;
	transition: 0.2s background-color;
	box-shadow: 0 1px 0 rgba(0,0,0,0.4), inset 0 1px 0 rgba(255,255,255,0.4);
}

.Button_add__6bU7- {
    margin-bottom: 32px;
	font-size: 18px;
    padding: 8px 16px;
    background-color: blueviolet;
    color: white;
    font-weight: bold;
}

.Button_delete__2cCUy {
    color: red;
    background-color: transparent;
}

.Button_ghost__v_8dN {
    background-color: rgb(240, 224, 255);
	border: 1px solid rgb(138, 43, 226);
	color: blueviolet;
}
.ItemEditor_editor__2FM7d {
	display: flex;
	justify-content: flex-start;
}
.ItemEditor_contentEditorSection__F2fOn {
	flex: 1 1;
}
.ItemEditor_textEditor__2Gf53 {
	width: 100%;
	min-height: 200px;
	font-size: 18px;
	line-height: 1.4;
}
.ItemEditor_buttons__Aof_g {
	display: flex;
	justify-content: flex-end;
}
.ItemEditor_cancelButton__2Roer {
	color: red;
	background: none;
	border: none;
	font-size: 18px;
}
.ItemEditor_saveButton__Wh19e {
	background: none;
	border: none;
	background-color: rgba(255, 148, 232, 0.8);
	padding: 8px 16px;
	color: rgba(0,0,0,0.6);
	font-weight: bold;
	font-size: 18px;
}
.EditableFile_fileEditorContainer__8WG8A {
    margin-bottom: 16px;
	position: relative;
	flex: 0 1;
}
.EditableFile_fileContainer__caGv6 {
    width: 260px;
	margin-right: 16px;
}
.EditableFile_displayedFile__3l951 {
    max-width:100%;
	max-height:100%;
}
.EditableFile_fileButton__33zBW {
    position: absolute;
	background: none;
	cursor: pointer;
	border: none;
	bottom: 0;
	left: 0;
	height: 44px;
	width: 260px;
	background-color: rgb(240, 224, 255);
	font-size: 14px;
	border: 1px solid blueviolet;
	color: blueviolet;
}
.EditableFile_fileList__1r4mc {
	position: fixed;
	z-index: 10000;
	top: 25%;
	left: 15%;
	padding: 20px 40px;
	background-color: white;
	border: 1px solid #e0e0e0;
	box-shadow: 0 0 20px rgba(0,0,0,0.6);
}
.EditableFile_fileList__1r4mc ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.EditableFile_filePreviewListItem__22VaZ {
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 8px;
}
.EditableFile_fileListCloseButton__13LJP {
	background: none;
	border: none;
	font-size: 24px;
	position: absolute;
	top: 4px;
	right: 4px;
	z-index: 99999;
}
.EditableFile_addNewFileButton__1kr8d {
	margin: 16px 0;
	color: blueviolet;
	font-weight: bold;
	cursor: pointer;
	text-align: center;
	display: block;
	width: 100%;
}
.EditableFile_imgPreview__1NHb3 {
    max-width: 150px;
}
.NoCause_HeaderBG__2AYCb {
	background-position: right;
	background-size: cover;
	background-color: #fff;
	margin-top: 32px;
	margin-bottom: 64px;
	padding: 0 0 32px;
    color: #ff00f7;
    border-top: 35px solid #ff00f7;
    box-shadow: 0 0 2px rgba(255, 0, 247, 0.4);
}
.Class_classNav__DFJfg {
    position: fixed;
    top: 8px;
    width: 100%;
    text-align: center;
}

.Class_classNav__DFJfg a {
    color: white;
    display: inline-block;
    margin-right: 8px;
    opacity: 0.6;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
}
.Class_classNav__DFJfg a:hover, .Class_classNav__DFJfg a:focus {
  opacity: 1;
}

.Class_classContainer__2TzCQ {
    background: #000;
    color: #feebff;
    padding-top: 64px;
}

.Class_stillPoseContainer__2_rJB {
    max-width: 1000px;
    height: 100vh;
}

.Class_bgOn__1jgn1 {
    background: -webkit-gradient( linear, left top, left bottom, from(rgba(255, 3, 129, 0.1)), to(rgba(255, 0, 221, 0.01)) ) rgb(1, 0, 0);
    background: linear-gradient( rgba(255, 3, 129, 0.1), rgba(255, 0, 221, 0.01) ) rgb(1, 0, 0);
}

.Class_video__MTlMO {
    max-width: 100%;
}

.Class_description__3i57a {
    font-family: 'Nanum Gothic', sans-serif;
    color: #f8d4fa;
    opacity: 0.8;
    text-align: center;
    font-size: 16px;
    line-height: 1.4;
    width: 70%;
    margin: 24px auto 64px;
}

.Class_videosContainer__1VOu_ {
  padding-top: 64px; 
  text-align: center;
}
.Class_videosContainer__1VOu_ iframe {
  margin-bottom: 128px;
  max-width: 100%;
}

@font-face {
    font-family: 'classy';
    src:  url('/assets/fonts/classy.eot?xit0xr');
    src:  url('/assets/fonts/classy.eot?xit0xr#iefix') format('embedded-opentype'),
      url('/assets/fonts/classy.ttf?xit0xr') format('truetype'),
      url('/assets/fonts/classy.woff?xit0xr') format('woff'),
      url('/assets/fonts/classy.svg?xit0xr#classy') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .Class_iconY__RQK6B, .Class_iconClass__2LvFQ {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'classy' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .Class_iconY__RQK6B:before {
    position: relative;
    top: -.16em;
    content: "\e900";
  }
  .Class_iconClass__2LvFQ:before {
    content: "\e901";
  }
  
.News_sectionContent__3MiaU {
	font-family: 'Nanum Gothic', sans-serif;
	font-size: 20px;
	line-height: 35px;
	margin-bottom: 80px;
	display: flex;
	justify-content: flex-start;
}
@media (max-width: 600px) {
	.News_sectionContent__3MiaU {
		display: block;
	}
	.News_imageContainer__3NEHL {
		display: block;
	}
}

.News_sectionContent__3MiaU h2 {
	/* margin: 0; */
}

.News_itemDate__1pKdm {
	font-size: 16px;
	opacity: 0.6;
}

.News_addButton__3gY4b, .News_editButton__m0K6K {
	background: none;
	border: none;
	margin-bottom: 32px;
	font-size: 18px;
	padding: 8px 16px;
	border-radius: 3px;
	font-weight: bold;
	color: white;
	background-color: blueviolet;
	cursor: pointer;
	-webkit-transition: 0.2s background-color;
	transition: 0.2s background-color;
	box-shadow: 0 1px 0 rgba(0,0,0,0.4), inset 0 1px 0 rgba(255,255,255,0.4);
}
.News_editButton__m0K6K {
	background-color: rgb(240, 224, 255);
	padding: 4px 8px;
	font-size: 14px;
	border: 1px solid blueviolet;
	color: blueviolet;
}
.News_deleteButton__3vulB  {
	border: none;
	color: red;
	font-weight: normal;
}
.News_addButton__3gY4b:hover, .News_addButton__3gY4b:focus {
	background-color: rgb(162, 92, 228);
}

.News_sectionContent__3MiaU h1 {
	font-size: 22px;
	margin: 0;
}

.News_sectionContent__3MiaU p {
	margin: 0;
	font-size: 16px
}

.News_imageContainer__3NEHL {
	width: 260px;
	min-width: 260px;
	margin-right: 16px;
}
@media (max-width: 600px) {
	.News_imageContainer__3NEHL {
		width: 100%;
	}
}
.News_displayedImage__3NA-R {
	max-width:100%;
	max-height:100%;
}
.News_displayedContent__30gOt {
	flex: 1 1;
	word-wrap: normal;
}
.News_pageContainer__3f7pi {
	max-width: 900px;
	margin: 0 auto;
	padding: 16px;
}

.Markdown_markdown__1_WEs img {
    max-width: 400px;
}
.Press_pageContainer__1JSXO {
	max-width: 900px;
	margin: 0 auto;
	padding: 16px;
}
.TextItem_sectionContent__3dT4g {
	font-size: 20px;
	line-height: 35px;
	white-space: pre-wrap;
	margin-bottom: 80px;
	display: flex;
	justify-content: flex-start;
}
@media (max-width: 600px) {
	.TextItem_sectionContent__3dT4g {
		display: block;
	}
	.TextItem_imageContainer__ZYmZa {
		display: block;
	}
}

.TextItem_itemDate__3zdGT {
	font-size: 16px;
	opacity: 0.6;
}

.TextItem_sectionContent__3dT4g h1 {
	font-size: 22px;
	margin: 0;
}

.TextItem_sectionContent__3dT4g p {
	margin: 0;
	font-size: 16px
}

.TextItem_imageContainer__ZYmZa {
	width: 260px;
	min-width: 260px;
	margin-right: 16px;
}
@media (max-width: 600px) {
	.TextItem_imageContainer__ZYmZa {
		width: 100%;
	}
}
.TextItem_displayedImage__2a6aZ {
	max-width:100%;
	max-height:100%;
}
.TextItem_displayedContent__s20M_ {
	flex: 1 1;
	word-wrap: normal;
}
.TextItem_pageContainer__7gvuX {
	max-width: 900px;
	margin: 0 auto;
}

.TextItem_ownPage__DNSAV {
	padding-top: 48px;
}
.PageContainer_container__12r2j {
    font-family: 'Nanum Gothic', sans-serif;
    max-width: 700px;
    line-height: 1.5;
}
.Crap_HeaderBG__1EN2- {
	background-position: right;
	background-size: cover;
	background-color: #fff;
	margin-top: 32px;
	padding: 0 0 32px;
	color: #9a4511;
}
