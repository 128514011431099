.HeaderBG {
	background-position: right;
	background-size: cover;
	background-color: #fff;
	margin-top: 32px;
	margin-bottom: 64px;
	padding: 0 0 32px;
    color: #ff00f7;
    border-top: 35px solid #ff00f7;
    box-shadow: 0 0 2px rgba(255, 0, 247, 0.4);
}