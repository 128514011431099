@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic&display=swap');

.link, .link:visited, .link:link {
    font-family: 'Nanum Gothic', sans-serif;
    color: blueviolet;
    font-size: 18px;
    line-height: 1.6;
    text-decoration: none;
}

.light, .light:visited, .light:link {
    display: block;
    color: #f8bbed;
}

.padLeft {
    padding-left: 32px;
}

.inline {
    display: inline-block;
    padding-right: 0.5em;
    font-size: 16px !important;
}

.plain {
    color: #222;
    text-decoration: none;
}
