@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:200,400,700&display=swap');
@import url("https://use.typekit.net/ghc0jsg.css");

.heading, .subHeading {
    text-align: left;
    font-family: 'Nanum Gothic', sans-serif;
    opacity: 0.9;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.4;
}

.heading_neon {
    font-family: ht-neon, 'Nanum Gothic', sans-serif;
}

.center {
    text-align: center;
    width: 100%;
}

.subHeading {
    font-size: 22px;
}

.large {
    font-size: 50px;
}

.noCause {
    font-size: 70px;
    font-weight: 200;
}

.light {
    color: #ffddf9;
}

.padTop {
    padding-top: 32px;
}

.inline {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    padding-right: 0.5em;
}

.bold {
    font-weight: bold;
}

.inline::after {
    content: ':';
}

.catastrophe {
    font-family: Arial;
}