.pageContainer {
	max-width: 900px;
	margin: 0 auto;
	padding: 16px;
}
.pageContainer img {
	max-width: 100%;
}

.PhotoDetail {
	max-width: 730px;
	margin: 0 auto;
	max-height: 80vh;
}
@media (min-width: 731px) {
	.PhotoDetail {
		min-height: 80vh;
	}
}
@media (max-width: 650px) {
	.PhotoDetail {
		max-height: 75vh;
	}
}
@media (max-width: 630px) {
	.PhotoDetail {
		max-height: 70vh;
	}
}
@media (max-width: 540px) {
	.PhotoDetail {
		max-height: 60vh;
	}
}
@media (max-width: 390px) {
	.PhotoDetail {
		min-height: 40vh;
	}
}

.PhotoDetail img {
	max-height: 80vh;
	max-width: 100%;
}

.photoGridContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.photo {
	flex: 0;
    padding-top: 32px;
	padding-bottom: 64px;
	margin-right: 24px;
}
.photo:nth-child(3n) {
	margin-right: 0;
}

.photoImg {
	cursor: pointer;
	min-width: 200px;
	max-width: 100%;
}

@media (max-width: 740px) {
	.photoImg {
		min-width: 160px;
	}
}
@media (max-width: 660px) {
	.photoImg {
		min-width: 140px;
	}
}
@media (max-width: 583px) {
	.photoImg {
		min-width: 120px;
	}
}
@media (max-width: 500px) {
	.photoImg {
		min-width: 100px;
	}
}
@media (max-width: 423px) {
	.photoImg {
		min-width: 80px;
	}
}

.popupPhotoContainer {
	position: fixed;
	width: 100%;
	text-align: center;
	top: 70px;
	left: 0;
}
.popupPhoto {
	position: relative;
	z-index: 99999;
}
.photoControls {
	max-width: 730px;
	margin: 24px auto 0;
	margin-top: 24px;
	position: relative;
    display: flex;
    flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 99999;
}

.prev, .next {
    width: 100px;
    appearance: none;
    border: none;
    background: none;
    font-size: 32px;
    opacity: 0.4;
}

.closeButton {
	border: none;
	appearance: none;
	background: none;
	position: fixed;
	font-size: 48px;
	top: 0;
	right: 16px;
	line-height: 1;
	height: 48px;
}

.overlay {
	background-color: rgb(255,255,255);
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	z-index: 99998;
}

.HeaderBG {
	background-position: right;
	background-size: cover;
	background-color: #f8c9cf;
	margin-top: 32px;
	padding: 32px 0;
	color: white;
	box-shadow: 0 30px 100px 20px #f8c9cf;
}