.editor {
	display: flex;
	justify-content: flex-start;
}
.contentEditorSection {
	flex: 1;
}
.textEditor {
	width: 100%;
	min-height: 200px;
	font-size: 18px;
	line-height: 1.4;
}
.buttons {
	display: flex;
	justify-content: flex-end;
}
.cancelButton {
	color: red;
	background: none;
	border: none;
	font-size: 18px;
}
.saveButton {
	background: none;
	border: none;
	background-color: rgba(255, 148, 232, 0.8);
	padding: 8px 16px;
	color: rgba(0,0,0,0.6);
	font-weight: bold;
	font-size: 18px;
}